@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 210 50% 98%;
    --foreground: 222.2 84% 4.9%;
    --card: 200 30% 99%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 215 50% 23%;
    --primary-foreground: 210 40% 98%;
    --secondary: 200 65% 45%;
    --secondary-foreground: 210 40% 98%;
    --muted: 185 25% 90%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 195 70% 50%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 200 30% 85%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 220 26% 14%;
    --foreground: 210 40% 98%;
    --card: 222 25% 18%;
    --card-foreground: 210 40% 98%;
    --popover: 222 25% 18%;
    --popover-foreground: 210 40% 98%;
    --primary: 195 85% 60%;
    --primary-foreground: 222 47% 11%;
    --secondary: 190 95% 39%;
    --secondary-foreground: 210 40% 98%;
    --muted: 223 25% 20%;
    --muted-foreground: 210 40% 80%;
    --accent: 185 85% 45%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 223 25% 22%;
    --input: 223 25% 22%;
    --ring: 195 85% 60%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-gradient-to-br from-background via-background to-muted text-foreground min-h-screen;
  }
  h1 {
    @apply scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl bg-gradient-to-r from-primary via-secondary to-accent bg-clip-text text-transparent;
  }
  .dark h1 {
    @apply bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-600 bg-clip-text text-transparent;
  }
  h2 {
    @apply scroll-m-20 text-3xl font-semibold tracking-tight text-secondary;
  }
  h3 {
    @apply scroll-m-20 text-2xl font-semibold tracking-tight text-primary;
  }
  p {
    @apply leading-7;
  }
  .section-title {
    @apply text-3xl font-bold tracking-tight mb-8 text-center text-secondary;
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2;
  }
  .btn-primary {
    @apply bg-gradient-to-r from-primary to-secondary text-primary-foreground hover:opacity-90;
  }
  .btn-secondary {
    @apply bg-gradient-to-r from-secondary to-accent text-secondary-foreground hover:opacity-90;
  }
  .btn-outline {
    @apply border border-input bg-background hover:bg-accent/10 hover:text-accent transition-all duration-200;
  }
  .card {
    @apply bg-gradient-to-br from-card via-background to-muted border-border hover:border-accent/30 transition-all duration-200 shadow-sm hover:shadow-md dark:shadow-none dark:hover:shadow-accent/5;
  }
  .skill-card {
    @apply bg-gradient-to-br from-card via-background to-muted border-2 hover:border-accent/30 transition-all duration-200 shadow-lg hover:shadow-xl hover:-translate-y-1 dark:shadow-none dark:hover:shadow-accent/10;
  }
  .timeline-item {
    @apply border-l-2 border-secondary/30 hover:border-secondary transition-colors duration-200;
  }
  .nav-link {
    @apply text-muted-foreground hover:text-accent transition-colors duration-200;
  }
  .waving-hand {
    @apply text-foreground bg-none;
  }
}
